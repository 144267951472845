<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import PostingCommon from '@/prototypes/PostingCommon';
  import BookingForm from '@/components/booking_components/BookingForm';

  export default {
    components: {
      'booking-form': BookingForm
    },
    extends: PostingCommon,
    computed: {
      ...mapGetters('OpenBookingStore', [
        'bookingFormProgress'
      ]),
      ...mapGetters('OpenBookingStore', ['organizationsList']),
      ...mapState('OpenBookingStore', {
        organizationName: ({organizationName}) => organizationName,
        departmentsList: ({departmentsList}) => departmentsList || [],
        organizations: ({organizations}) => organizations || {},
        isExistingCustomer: ({openBooking}) => openBooking.isExistingCustomer,
        openBookingType: ({openBooking}) => openBooking.openBookingType,
        organization: ({openBooking}) => openBooking.organization,
        department: ({openBooking}) => openBooking.department,
        firstName: ({openBooking}) => openBooking.firstName,
        lastName: ({openBooking}) => openBooking.lastName,
        email: ({openBooking}) => openBooking.email,
        phoneCode: ({openBooking}) => openBooking.phoneCode,
        phoneNumber: ({openBooking}) => openBooking.phoneNumber,
        bookingRef: ({openBooking}) => openBooking.bookingRef,
        paymentBookingRef: ({openBooking}) => openBooking.paymentBookingRef,
        caseNumber: ({openBooking}) => openBooking.caseNumber,
        orgNumber: ({openBooking}) => openBooking.orgNumber,
        paymentCompany: ({openBooking}) => openBooking.paymentCompany,
        paymentOrgNumber: ({openBooking}) => openBooking.paymentOrgNumber
      }),
      sectionsList() {
        return [
          this.assignmentInformationSection,
          this.invoiceInformationSection
        ];
      },
      chosenOrganization() {
        return this.organizationsList.find((organization) => organization.id == this.organization) || {};
      },
      pageConfig() {
        return this.organizationName
          ? helpers.openBookingFieldConfig(this.organizationName, this)
          : {};
      },
      fieldsConfig() {
        const fieldsConfig = this.isExistingCustomer ? this.pageConfig.existingCustomerFields : this.pageConfig.newCustomerFields;
        return fieldsConfig || {};
      },
      paymentMethod() {
        const currentOrgNumber = this.$store.getters['OpenBookingStore/currentOrgNumber'];

        if (this.paymentCompany === 'someone_else' && this.paymentOrgNumber) {
          const template = this.$gettext('Someone else / Org.:%{orgNumber}');
          return this.$gettextInterpolate(template, {
            orgNumber: this.paymentOrgNumber
          });
        } else if (currentOrgNumber) {
          const template = this.$gettext('Org.:%{orgNumber}');
          return this.$gettextInterpolate(template, {
            orgNumber: currentOrgNumber
          });
        }
      },
    },
    methods: {
      ...mapActions('OpenBookingStore', [
        'setError',
        'removeErrors'
      ]),
      validateEmail(email) {
        return helpers.validation.validateEmail(email);
      },
      validateNAVEmail(email) {
        const re = /@nav.no|@tikktalk.com$/;
        return re.test(email);
      },
      validateSharedFields() {
        let isValidForm = true;
        const {firstName, lastName, email, phone} = this.fieldsConfig;

        if (firstName && firstName.required && !this.firstName.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'firstName',
            errorText: this.$gettext('Your first name is required.'),
            typeError: 'missingData'
          });
        }
        if (lastName && lastName.required && !this.lastName.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'lastName',
            errorText: this.$gettext('Your last name is required.'),
            typeError: 'missingData'
          });
        }
        if (email) {
          if (email.required && !this.email.trim()) {
            isValidForm = false;
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('Email is required.'),
              typeError: 'missingData'
            });
          } else if (!this.validateEmail(this.email.trim())) {
            isValidForm = false;
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('Email is not valid'),
              typeError: 'wrongData'
            });
          } else if (this.organizationName === 'nav' && !this.validateNAVEmail(this.email.trim())) {
            isValidForm = false;
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('This page is for NAV employees. If you are not a NAV employee, please <a class="sk-link" href="/auth/sign_in">login</a> or <a class="sk-link" href="/auth/sign_up">create an account</a> to book an interpreter.'),
              typeError: 'wrongData'
            });
          }
        }
        if (phone) {
          if (!this.phoneCode) {
            isValidForm = false;
            this.setError({
              fieldName: 'phoneCode',
              errorText: this.$gettext('Please choose a country code'),
              typeError: 'missingData'
            });
          }
          if (!this.phoneNumber) {
            isValidForm = false;
            this.setError({
              fieldName: 'phoneNumber',
              errorText: this.$gettext('Enter a phone number'),
              typeError: 'missingData'
            });
          }
        }
        return isValidForm;
      },
      validateOrganizationField() {
        let isValidForm = true;
        const {organization, orgNumber} = this.fieldsConfig;

        if (organization) {
          if (organization.required && this.organizationsList.length && !this.organization) {
            isValidForm = false;
            this.setError({
              fieldName: 'organization',
              errorText: this.$gettext('Organization is required.'),
              typeError: 'missingData'
            });
          }
          if (orgNumber && orgNumber.required && ['', 'other'].includes(this.organization) && (!this.orgNumber || this.orgNumber.length < 9)) {
            isValidForm = false;
            this.setError({
              fieldName: 'orgNumber',
              errorText: !this.orgNumber ? this.$gettext('Organization number is required.') : this.$gettext('Organization number is invalid'),
              typeError: 'missingData'
            });
          }
        }
        return isValidForm;
      },
      validateDepartmentField() {
        let isValidForm = true;
        const {department} = this.fieldsConfig;

        if (department && department.required && this.departmentsList.length && !this.department) {
          isValidForm = false;
          this.setError({
            fieldName: 'departments',
            errorText: this.$gettext('Please select your department.'),
            typeError: 'missingData'
          });
        }
        return isValidForm;
      },
      validateOrgSpecificFields() {
        let isValidForm = true;
        const {bookingRef, paymentBookingRef, caseNumber} = this.fieldsConfig;

        if (bookingRef && bookingRef.required) {
          if (!this.bookingRef.trim()) {
            isValidForm = false;
            this.setError({
              fieldName: 'bookingRef',
              errorText: this.$gettextInterpolate('%{bookingRefName} is required.', {
                bookingRefName: bookingRef.customName || this.$gettext('Booking ref.')
              }),
              typeError: 'missingData'
            });
          }
        }
        if (paymentBookingRef && paymentBookingRef.required && !this.paymentBookingRef.trim()) {
          isValidForm = false;
          const NAVCodeErrorText = this.organizationName === 'nav'
            ? this.$gettext('Valid formats for employees in NAV are three letters and four digits (eg ABC1234), or one letter and six digits (eg A123456). See <a class="sk-link" href="https://www.nav.no/no/NAV+og+samfunn/Om+NAV/Innkjop+og+faktura" target="_blank">NAV purchase and invoice</a> for more details.')
            : '';
          this.setError({
            fieldName: 'paymentBookingRef',
            errorText: `${this.$gettextInterpolate('%{paymentBookingRefName} is required.', {
              paymentBookingRefName: paymentBookingRef.customName || this.$gettext('Invoice ref.')
            })} ${NAVCodeErrorText}`,
            typeError: 'missingData'
          });
        }
        if (caseNumber && caseNumber.required && !this.caseNumber.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'caseNumber',
            errorText: this.$gettextInterpolate('%{caseNumberName} is required.', {
              caseNumberName: caseNumber.customName || this.$gettext('Case number')
            }),
            typeError: 'missingData'
          });
        }
        return this.validateDepartmentField() & isValidForm;
      },
      validatePaymentMethodFields() {
        let isValidForm = true;
        const {paymentMethod} = this.fieldsConfig;

        if (paymentMethod && paymentMethod.required) {
          if (!this.paymentCompany) {
            isValidForm = false;
            this.setError({
              fieldName: 'paymentCompany',
              errorText: this.$gettext('Please choose option.'),
              typeError: 'missingData'
            });
          } else if (this.paymentCompany === 'someone_else' && !this.paymentOrgNumber) {
            isValidForm = false;
            this.setError({
              fieldName: 'paymentOrgNumber',
              errorText: this.$gettext('Org. number can\'t be blank.'),
              typeError: 'missingData'
            });
          }
        }
        return isValidForm;
      },
      handleSuccess() {
        this.$emit('successsubmit', this.goToSuccessPage);
      }
    }
  };
</script>
