(<template>
  <booking-form :validate-func="formValidation"
                :success-handling-func="handleSuccess"
                :submit-button-text="submitButtonText"
                :error-handling-func="handleError"
                :sections-list="sectionsList"
                :summary-obj="summaryObj"
                :send-info-func="sendInfoFunc" />
</template>)

<script>
  import {mapActions} from 'vuex';
  import OpenBookingPrototype from '@/prototypes/OpenBookingPrototype';

  export default {
    extends: OpenBookingPrototype,
    computed: {
      assignmentInformationSection() {
        return {
          title: this.$gettext('Assignment information'),
          lines: [{
            components: [{
              name: 'language-section',
              params: {
                langToText: this.$gettext('Language'),
                showMyLang: false
              }
            }]
          }, {
            components: [{
              name: 'date-time-section'
            }]
          }, {
            components: [{
              name: 'booking-overtime-check'
            }]
          }, {
            components: [{
              name: 'subject-section'
            }]
          }, {
            components: [{
              name: 'assignment-type-section',
              params: {
                isOpenPage: true
              }
            }]
          }, {
            components: [{
              name: 'gender-section'
            }]
          }, {
            components: [{
              name: 'qualification-section'
            }]
          }, {
            components: [{
              name: 'extra-info-inner-section',
              params: {
                showExtraInfoNote: true
              }
            }]
          }, {
            components: [{
              name: 'attachments-inner-section'
            }]
          }, {
            components: [{
              name: 'participants-section'
            }]
          }]
        };
      },
      invoiceInformationSection() {
        return {
          title: this.$gettext('Invoice information'),
          lines: [{
            components: [{
              name: 'invoice-info-section',
              params: {
                fieldsConfig: this.fieldsConfig,
                showPaymentMethodSection: true
              }
            }]
          }]
        };
      },
      summaryObj() {
        return {
          btnText: this.$gettext('Publish assignment'),
          job: {
            languageFromId: this.languageFrom,
            languageToId: this.languageTo,
            sessionType: this.assignmentType,
            qualificationId: this.qualification,
            startTime: this.fullStartTime,
            finishTime: this.fullFinishTime,
            sex: this.sex,
            booker: this.booker,
            organization: this.chosenOrganization.name,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phoneNumber: `${this.phoneCode}${this.phoneNumber}`,
            department: {
              departmentId: this.department,
              departmentsList: this.departmentsList
            },
            bookingRef: this.bookingRef,
            paymentBookingRef: this.paymentBookingRef,
            caseNumber: this.caseNumber,
            subject: this.subject,
            category: {
              categoryId: this.category,
              categoriesList: this.categoriesList
            },
            extra: this.extraInfo,
            orgNumber: this.orgNumber,
            participantsEmails: this.participantEmails,
            participantsPhones: this.participantPhones,
            paymentMethodName: this.paymentMethod
          }
        };
      },
      submitButtonText() { return this.$gettext('Book now'); }
    },
    methods: {
      ...mapActions({
        preselectStaticData: 'PostingNotifierStore/preselectInterpretationStaticData'
      }),
      formValidation() {
        this.removeErrors();
        const assignmentInfoIsValid = this.validateLanguagesSection()
          & this.validateDateTimeSection()
          & this.validateSubjectSection()
          & this.validateAssignmentTypeSection()
          & this.validateParticipantsSection()
          & this.validateProcessingSections();
        const organizationFieldIsValid = this.validateOrganizationField() & this.validateOrgSpecificFields() & this.validatePaymentMethodFields();

        return this.validateSharedFields() & organizationFieldIsValid & assignmentInfoIsValid;
      },
      sendInfoFunc() {
        return this.$store.dispatch('PostInterpretationStore/createLogoutJob');
      },
      goToSuccessPage() {
        this.$router.push(this.$makeRoute({name: 'SuccessOpenBookingInterpretation', params: {organization: this.openBookingType}}));
      },
      handleError(error, context) {
        let isHandledError = false;
        const errors = error?.data?.errors;

        if (errors?.job_not_exists?.includes('Job exists')) {
          isHandledError = true;
          this.$emit('postassignfailed');
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'same-job-modal',
              data: {context}
            });
          }, 0);
        }
        if (errors?.time_available?.includes('This time isn\'t available')) {
          isHandledError = true;
          this.$emit('postassignfailed');
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('It seems you already have a event at the selected time in your calendar. Please choose a different time for this event.')
            });
          }, 0);
        }
        if (errors?.email?.includes('You can create assignment only for demander')) {
          isHandledError = true;
          this.$emit('postassignfailed');
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('This email is associated with an interpreter account. Please use another email, or contact us to convert your account.')
            });
          }, 0);
        }
        if (errors['global!']) {
          this.setError({
            fieldName: 'paymentOrgNumber',
            errorText: errors['global!'][0]
          });

          this.$emit('postassignfailed');
          this.$store.dispatch('ModalStore/closeModal');
        }
        if (errors?.booker) {
          const bookerErrors = errors.booker;

          if (bookerErrors.email?.length && bookerErrors.email.includes('Invalid email')) {
            isHandledError = true;
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('Please check the email address you have provided for spelling errors, and try again.')
            });
          }
          if (bookerErrors.length && bookerErrors.includes('User with given email does not exist')) {
            isHandledError = true;
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('Are you using your work email?<br/>Please use your work email or change to \'I am a new customer\' to place your order')
            });
          }
          this.$emit('postassignfailed');
          this.$store.dispatch('ModalStore/closeModal');
        }
        if (errors?.phone_number?.includes('Invalid phone')) {
          isHandledError = true;
          this.setError({
            fieldName: 'phoneNumber',
            errorText: this.$gettext('Phone number is invalid')
          });
          this.$emit('postassignfailed');
          this.$store.dispatch('ModalStore/closeModal');
        }
        if (errors?.org_number?.includes('Organisation number is invalid')) {
          isHandledError = true;
          this.setError({
            fieldName: 'orgNumber',
            errorText: this.$gettext('Organisation number is invalid.')
          });
          this.$emit('postassignfailed');
          this.$store.dispatch('ModalStore/closeModal');
        }
        if (errors?.cc_emails) {
          isHandledError = true;
          this.$store.commit('PostingStore/ParticipantsStore/setInviteesErrors', {
            data: error.data.errors.cc_emails,
            context: this
          });
          this.$emit('postassignfailed');
          this.$store.dispatch('ModalStore/closeModal');
        }
        if (errors?.confirmation_phones?.includes('One or more confirmation phones are invalid')) {
          const errorText = this.$gettext('SMS messages can be sent to valid mobile phone numbers. Please check the number you have provided.');

          isHandledError = true;
          this.$store.dispatch('PostingStore/ParticipantsStore/catchParticipantsSelect', true);
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'ParticipantsStore',
            fieldName: 'participantPhones',
            errorText
          });
          this.$emit('postassignfailed');
          this.$store.dispatch('ModalStore/closeModal');
        }
        if (errors?.invitees) {
          isHandledError = true;
          this.$store.commit('PostingStore/ParticipantsStore/setInviteesErrors', {
            data: error.data.errors.invitees,
            context: this
          });
          this.$emit('postassignfailed');
        }
        if (!isHandledError) {
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
        setTimeout(() => {
          this.$scrollToErrors();
        }, 100);
      }
    },
    mounted() {
      if (this.dataPromise) {
        this.dataPromise.then(() => {
          this.preselectStaticData();
        });
      } else {
        this.preselectStaticData();
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.dispatch('PostInterpretationStore/clearStore');
      next();
    }
  };
</script>
